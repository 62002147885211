import axios from "axios";
// import Vue from "vue";
import router from "./router";

export function jwtInterceptor() {
  //add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      if (config.url != "https://api.ipify.org/?format=json") {
        if (!config.headers["Content-Type"]) config.headers["Content-Type"] = "application/json";
        if (!config.headers["Authorization"] && router.app.$store.getters["authen/getToken"]) {
          config.headers["Authorization"] = `Bearer ${router.app.$store.getters["authen/getToken"]}`;
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status == 500 &&
        error.response.data.message === "jwt expired" &&
        !originalRequest._retry &&
        !error.config.url.includes("auth/access_token")
      ) {
        originalRequest._retry = true;
        await router.app.$store.dispatch("authen/fetchAccessToken");
        originalRequest.headers["Authorization"] = `Bearer ${router.app.$store.getters["authen/getToken"]}`;
        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );
}
