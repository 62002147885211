const publicData = {
    apiURL : `${process.env.VUE_APP_SMEGP_BASE_API}/smegp/`,
    login_ID : "",
    mobile : "",
    SME_ID : "",
    FIRM_NO : "",
    MONTH_START: [1,6],// change to array [date, month], Jan = 0,...,Dec = 11, renew start date month of the year (start at 1 july)
    CERT_START_DATE: [1,6],//[1,6]//[date, month] Jan = 0,...,Dec = 11//download cert. start date month
    ADMIN_CERT_START_DATE: [1,6],//[date, month] Jan = 0,...,Dec = 11 //approve cert. year(admin review and approve) start date month
}

const certificateData = {
    apiURL : `${process.env.VUE_APP_SMEGP_CERTIFICATE_API}/smegp/`,
    login_ID : "",
    mobile : "",
    SME_ID : "",
    FIRM_NO : "",
}

export { publicData, certificateData };