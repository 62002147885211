import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import vco from "v-click-outside";
import router from "./router/index";
import Scrollspy from "vue2-scrollspy";
import VueSweetalert2 from "vue-sweetalert2";
import Multiselect from "vue-multiselect";
import VueGtag from "vue-gtag";
import VueMatomo from "vue-matomo";
import VueHead from "vue-head";
import Vuex from "vuex";
import store from "@/state/store";
import VueRouter from "vue-router";

Vue.use(Vuex);
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
// import "./authUtils";
// import AccessibilityToolbar from 'vue-accessibility-toolbar'
import { jwtInterceptor } from "./jwt-interceptor";

// carousel section
import { Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay } from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

jwtInterceptor();
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));

import "swiper/swiper-bundle.css";
// end carousel section

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "../src/design/app.scss";

import App from "./App.vue";
import VueThailandAddress from "vue-thailand-address";
import "vue-thailand-address/dist/vue-thailand-address.css";

Vue.use(VueThailandAddress);
Vue.component("multiselect", Multiselect);

// import InstagramCropper from 'vue-instagram-cropper'
// Vue.component('instagram-cropper', InstagramCropper);
// import { Plugin } from 'vue-instagram-cropper'
// Vue.use(Plugin);

import i18n from "./i18n";

/*
import {
  configureFakeBackend
} from './helpers/fake-backend';
*/

import VueCarousel from "vue-carousel";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Hotjar from "vue-hotjar";
import Zendesk from "@dansmaculotte/vue-zendesk";

Vue.use(Zendesk, {
  key: "ee5dbc07-097e-4340-a220-045f8ed915d7",
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: "#2196F3",
        launcherText: "#FFF",
      },
      // offset: { horizontal: '10px', vertical: '0' }
    },
  },
});
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

Vue.use(VueHead);
Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueCarousel);
// Vue.component("AccessibilityToolbar", AccessibilityToolbar)
// Configuration VueAnalytics
Vue.use(VueGtag, {
  config: {
    id: "G-MM48ZJXJ42",
  },
  appName: "THAI SME-GP",
  pageTrackerScreenviewEnabled: true,
  enabled: true,
  bootstrap: true,
});

Vue.use(VueMatomo, {
  host: "https://matomo.thaisme.one",
  siteId: 1,
  trackerFileName: "matomo",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
  trackSiteSearch: false,
});

/*
Sentry.init({
  Vue,
  dsn: "https://51ad0e4da61a46fdaf46fba533d8af54@o968342.ingest.sentry.io/5935646",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

Vue.use(Hotjar, {
  id: "2669366",
  isProduction: true,
});

Vue.component("apexchart", VueApexCharts);

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
