import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import routes from "./routes";
import store from "../state/store";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          x: 0,
          y: 80,
        },
        behavior: "smooth",
      };
    } else if (to.path == from.path) {
      return {
        x: 0,
        y: 0,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

router.beforeEach(async (to, from, next) => {
  if (store && !store.getters["authen/getUser"]) {
    await store.dispatch("authen/fetchAccessToken");
  }

  const userRole = store.getters["authen/getRoles"];
  const redirectUrl = sessionStorage.getItem("redirectUrl");
  const userData = store.getters["authen/getUser"];
  if (to.name === "LoginV2" && store.getters["authen/getUser"]) {
    next("/");
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userData) {
      if (
        userRole.some((role) => to.meta.allowRoles.some((allowRoles) => allowRoles === role)) ||
        (to.meta.allowRoles.some((allowRoles) => allowRoles === "vendor_business_owner") && userRole.length === 0)
      ) {
        if (redirectUrl && redirectUrl !== "/loginv2") {
          sessionStorage.removeItem("redirectUrl");
          next(redirectUrl);
          return;
        }
        if (redirectUrl) {
          sessionStorage.removeItem("redirectUrl");
        }
        next();
        return;
      } else {
        next("/");
        return;
      }
    } else {
      next({ name: "LoginV2", query: { redirectUrl: to.fullPath } });
      return;
    }
  } else {
    if (redirectUrl) {
      sessionStorage.removeItem("redirectUrl");
      sessionStorage.removeItem("firstLoginSession");
      window.location.href = redirectUrl
      return;
    }
    if (sessionStorage.getItem("firstLoginSession") == "1") {
      sessionStorage.removeItem("firstLoginSession");
      if (userData && userRole.length === 0) {
        router.push({
          name: "AddSME",
        });
        return;
      } else if (userRole.includes("admin_approver_level1")) {
        router.push({
          name: "Pre Approve",
        });
        return;
      } else if (userRole.includes("admin_approver_level2")) {
        router.push({
          name: "Approve",
        });
        return;
      } else if (userRole.includes("super_admin")) {
        router.push({
          name: "Approve",
        });
        return;
      } else {
        next();
        return;
      }
    }

    next();
    return;
  }
});

export default router;
