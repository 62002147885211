<template>
  <div id="preload-sme-gp" v-if="!isLoaded">
    <div>
      <span class="logo-sm">
        <img class="lg-img" src="https://s3gw.inet.co.th:8082/smegp-storage/public/images/logo/logo-light.png" alt height="100px" />
        <img class="sm-img" src="https://s3gw.inet.co.th:8082/smegp-storage/public/images/logo/logo-th-gp.png" alt height="80px" />
      </span>
      <b-spinner />
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    this.$router.onReady(
      () => {
        this.isLoaded = true;
      },
      () => {
        this.isLoaded = true;
      }
    );
    setTimeout(() => {
      this.isLoaded = true;
    }, 3000);
  },
};
</script>
<style lang="scss">
#preload-sme-gp {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-bottom: 1rem;
      mix-blend-mode: multiply;
    }
  }
}
@media screen and (max-width: 800px) {
  .sm-img {
    display: block;
  }
  .lg-img {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .sm-img {
    display: none;
  }
  .lg-img {
    display: block;
  }
}
</style>
