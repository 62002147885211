import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    component: () => import("./views/dashboards/default"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/product/add-product/:actionType/:smeId",
    name: "Add Product",
    component: () => import("./views/product/add-product"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve() {
        // const loginUrl = Vue.prototype.$keycloak.createLoginUrl();
        //window.location.replace(loginUrl)
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "default",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/create",
    name: "CreateAccount",
    component: () => import("./views/account/create-account"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "default",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/account/logout"),
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  /*
  {
    path: "*",
    redirect: "404",
  },
  */
  {
    path: "/product",
    name: "Products",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/product/products"),
  },
  {
    path: "/product/:id",
    name: "Product Detail",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/product/product-detail"),
  },
  {
    path: "/banks",
    name: "Banks",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/dashboards/bank-detail"),
  },
  {
    path: "/government/purchase-report-64",
    name: "Government Report",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/dashboards/form-gov"),
  },
  {
    path: "/account/sme/add-sme",
    name: "AddSME",
    meta: {
      requiresAuth: true,
      allowRoles: ["vendor_business_owner"],
    },
    component: () => import("./views/sme/add-sme"),
  },
  {
    path: "/account/sme/edit-sme/",
    name: "EditSME",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/sme/edit-sme"),
  },
  // {
  //   path: '/product/board-product',
  //   name: 'Board Product',
  //   meta: {
  //     requiresAuth: false,
  //   },
  //   component: () => import('./views/product/board-product')
  // },
  {
    path: "/product/add-service",
    name: "Add Service",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/product/add-service"),
  },
  {
    path: "/sme/:id",
    name: "SME Profile",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/account/sme/profile"),
  },
  // {
  //   path: '/sme/:id',
  //   redirect: "/sme/:id/profile"
  // },
  {
    path: "/admin/preapprove",
    name: "Pre Approve",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/preapprove"),
  },
  {
    path: "/admin/preapprove-detail/:id",
    name: "Pre Approve Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/preapprove-detail"),
  },
  {
    path: "/admin/approve",
    name: "Approve",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/approve"),
  },
  {
    path: "/admin/approve-detail/:id",
    name: "Approve Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/approve-detail"),
  },
  {
    path: "/account/sme/:id/:tab",
    name: "SME Account",
    meta: {
      requiresAuth: true,
      allowRoles: ["vendor_business_owner", "admin_approver_level1", "admin_approver_level2"],
    },
    component: () => import("./views/account/sme/profile"),
  },
  {
    path: "/account/sme/:id",
    redirect: "/account/sme/:id/profile",
  },
  {
    path: "/dashboards/report",
    name: "Report",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin", "oss_officer"],
    },
    component: () => import("./views/dashboards/report"),
  },
  {
    path: "/dashboards/report-sme",
    name: "Report SME",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin", "oss_officer"],
    },
    component: () => import("./views/dashboards/report-sme"),
  },
  {
    path: "/dashboards/report/detail/:id",
    name: "Report Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/report-detail"),
  },
  {
    path: "/dashboards/report-product",
    name: "Report Product",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin", "oss_officer"],
    },
    component: () => import("./views/dashboards/report-product"),
  },
  // {
  //   path: "/dashboards/report-oss",
  //   name: "Report oss",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: [
  //       "admin_approver_level1",
  //       "admin_approver_level2",
  //       "super_admin",
  //     ],
  //   },
  //   component: () => import("./views/dashboards/report-oss"),
  // },
  {
    path: "/admin/approve-product",
    name: "Product Approve",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/approve-product"),
  },
  {
    path: "/admin/preapprove-product",
    name: "Product Pre Approve",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/preapprove-product"),
  },
  {
    path: "/admin/account",
    name: "Admin account",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    component: () => import("./views/account/admin/account"),
  },
  {
    path: "/certificate/:id",
    name: "Certificate",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/certificate/certificate-detail"),
  },
  {
    path: "/sample_cert",
    meta: {
      requiresAuth: false,
    },
    beforeEnter() {
      window.open("https://thaismegp.com/egp/User_Control/Sample_Cer_SME-GP.pdf", "_blank");
    },
  },
  {
    path: "/admin/news",
    name: "Admin news",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    component: () => import("./views/account/admin/news"),
  },
  {
    path: "/news/detail/:id",
    name: "News Detail",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/dashboards/news-detail"),
  },
  {
    path: "/olduser",
    name: "OldUser",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/sme/olduser"),
  },
  // {
  //   path: "/admin/olduser-admin",
  //   name: "OldUser None Otp",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: ["admin_approver_level2", "super_admin"],
  //   },
  //   component: () => import("./views/sme/olduser-noneotp"),
  // },
  {
    path: "/admin/video",
    name: "Admin video",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    component: () => import("./views/account/admin/video"),
  },
  {
    path: "/egp/cer_detail.aspx",
    //name: "default",
    component: () => import("./views/dashboards/default"),
    beforeEnter(to) {
      const cert_redirect_url = "https://v1.thaismegp.com/" + to.fullPath;
      window.location.href = cert_redirect_url;
    },
  },
  {
    path: "*",
    redirect: {
      name: "default",
    },
  },
  // {
  //   path: "/admin/search-sme",
  //   name: "Search sme",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: [
  //       "admin_approver_level1",
  //       "admin_approver_level2",
  //       "super_admin",
  //     ],
  //   },
  //   component: () => import("./views/dashboards/search-sme"),
  // },
  {
    path: "/admin/register",
    name: "Admin register",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/account/admin/register"),
  },
  {
    path: "/admin/sme-account",
    name: "Admin Sme Owner account",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin", "admin_approver_level1"],
    },
    component: () => import("./views/account/admin/sme-owner-account"),
  },
  // {
  //   path: "/admin/sme-account",
  //   name: "Admin SME Account",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: ["super_admin", "admin_approver_level1"],
  //   },
  //   component: () => import("./views/account/admin/sme-account"),
  // },
  {
    path: "/edit-profile",
    name: "Edit profile",
    component: () => import("./views/account/edit-profile"),
    meta: {
      requiresAuth: true,
      allowRoles: ["vendor_business_owner"],
    },
  },
  // {
  //   path: "/dashboards/report-purchase",
  //   name: "Report Purchase",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: ["admin_approver_level2", "super_admin"],
  //   },
  //   component: () => import("./views/dashboards/report-purchase"),
  // },
  {
    path: "/dashboards/report-purchase/:id",
    name: "Report Purchase Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/report-purchase-detail"),
  },
  {
    path: "/dashboards/view-report-purchase/:id",
    name: "View Report Purchase Detail",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/dashboards/report-purchase-view"),
  },
  {
    path: "/admin/prerenew",
    name: "Pre Approve Renew",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/prerenew"),
  },
  {
    path: "/admin/prerenew-detail/:id",
    name: "Pre Approve Renew Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/prerenew-detail"),
  },
  {
    path: "/admin/renew",
    name: "Renew",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/renew"),
  },
  {
    path: "/admin/renew-detail/:id",
    name: "Renew Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "super_admin"],
    },
    component: () => import("./views/dashboards/renew-detail"),
  },
  {
    path: "/dashboards/report-renew",
    name: "Report renew",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin", "oss_officer"],
    },
    component: () => import("./views/dashboards/report-renew"),
  },
  {
    path: "/dashboards/report-renew/:id",
    name: "Report Renew Detail",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["admin_approver_level1", "admin_approver_level2", "super_admin"],
    },
    component: () => import("./views/dashboards/report-renew-detail"),
  },
  {
    path: "/admin/banner",
    name: "Banner",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    component: () => import("./views/account/admin/banner"),
  },
  {
    path: "/admin/:category/preview",
    name: "Preview",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    props: (route) => ({ query: route.query.img, category: route.params.category }),
    component: () => import("./views/account/admin/preview"),
  },
  {
    path: "/admin/popup",
    name: "Entry popup",
    meta: {
      hideChat: true,
      requiresAuth: true,
      allowRoles: ["super_admin"],
    },
    component: () => import("./views/account/admin/popup"),
  },
  // {
  //   path: "/admin/oss-management",
  //   name: "Oss management",
  //   meta: {
  //     hideChat: true,
  //     requiresAuth: true,
  //     allowRoles: ["super_admin"],
  //   },
  //   component: () => import("./views/account/admin/oss-management"),
  // },
  {
    path: "/loginv2",
    name: "LoginV2",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/utility/loginV2.vue"),
  },
  {
    path: "/contactus",
    name: "Contactus",
    component: () => import("./views/contact-us.vue"),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/smes",
    name: "Smes",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./views/sme/search-smes"),
  },
  {
    path: "/userid",
    name: "Get user id",
    meta: {
      requiresAuth: true,
      allowRoles: ["vendor_business_owner", "admin_approver_level1", "admin_approver_level2", "super_admin", "oss_officer"]
    },
    component: () => import("./views/utility/userId"),
  },
];
