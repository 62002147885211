import axios from "axios";
import jwt_decode from "jwt-decode";
import Vue from "vue";

export const state = {
  role: [],
  user: null,
  accessToken: null,
};

export const mutations = {
  /**
   *
   * @param {{role: {id: string; name: string}[]; user:object}} state
   * @param {object} payload
   */
  setUser(state, payload) {
    state.user = payload;
  },
  setToken(state, payload) {
    state.accessToken = payload;
  },
  /**
   *
   * @param {{role: any[]; user: object}} state
   * @param {object[]} payload
   */
  setRole(state, payload) {
    state.role = payload;
  },
  clearRole(state) {
    state.role = [];
  },
  clearUser(state) {
    state.user = null;
  },
  clearToken(state) {
    state.accessToken = null;
  },
};

export const actions = {
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  /**
   *
   * @param {*} param0
   * @param {{id: string; name: string}[]} roles
   */
  setRole({ commit }, roles) {
    commit("setRole", roles);
  },
  clearRole({ commit }) {
    commit("clearRole");
  },
  clearUser({ commit }) {
    commit("clearUser");
  },
  loginDigitalId() {
    window.location.href = `${process.env.VUE_APP_SMEGP_BASE_API}/auth/digitalid/login?backToPath=${window.location.origin}`;
  },
  loginDbdId() {
    window.location.href = `${process.env.VUE_APP_SMEGP_BASE_API}/auth/dbd/login?backToPath=${window.location.origin}`;
  },
  setUserFromToken({ commit }, payload) {
    const decodeValue = jwt_decode(payload);
    commit("setUser", decodeValue);
  },
  setRoleFromToken({ commit }, payload) {
    const decodeValue = jwt_decode(payload);
    commit("setRole", decodeValue.USER_ROLE);
  },
  async fetchAccessToken({ commit, dispatch }) {
    return new Promise(async (resolve) => {
      try {
        const res = await axios.post(`${process.env.VUE_APP_SMEGP_BASE_API}/auth/access_token`, {}, { withCredentials: true });
        const token = res.data.accessToken;
        commit("setToken", token);
        dispatch("setUserFromToken", token);
        dispatch("setRoleFromToken", token);

        const decodeValue = jwt_decode(token);
        if (decodeValue.login_by === "digital_id") {
          window._paq.push(["DI-USER_ID", decodeValue.sub]);
        } else if (decodeValue.login_by === "dbd") {
          window._paq.push(["DBD-USER_ID", decodeValue.sub]);
        }

        const ipRes = await axios.get("https://api.ipify.org/?format=json");
        Vue.prototype.$gtag.customMap({
          user_infonation: "session",
        });

        Vue.prototype.$gtag.event("session", {
          type: "page_open",
          ip_address: ipRes.data.ip,
          username: decodeValue.sub,
        });

        Vue.prototype.$gtag.event("page_open", {
          event_category: "session",
          event_label: "ip_address",
          value: ipRes.data.ip,
        });

        Vue.prototype.$gtag.event("page_open", {
          event_category: "session",
          event_label: "username",
          value: decodeValue.sub,
        });

        return resolve(token);
      } catch (e) {
        return resolve(null);
      }
    });
  },
  async logout({ commit }) {
    return new Promise(async (resolve) => {
      try {
        commit("clearUser");
        commit("clearRole");
        commit("clearToken");
        window.location.href = `${process.env.VUE_APP_SMEGP_BASE_API}/auth/logout?backToPath=${window.location.origin}`;
        resolve();
      } catch (error) {
        commit("clearUser");
        commit("clearRole");
        commit("clearToken");
        resolve();
      }
    });
  },
};

export const getters = {
  getRoles() {
    return state.role && state.role.length > 0 ? state.role.map((item) => item.name) : [];
  },
  getUser() {
    return state.user;
  },
  getToken() {
    return state.accessToken;
  },
};
