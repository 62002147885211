<template>
  <div v-if="showConsent">
    <div class="ck-wrapper">
      <div class="ck-body">
        <button type="button" class="close-consent-btn" aria-label="close-consent-button" @click="close">
          <img src="./x-symbol.svg" aria-label="close-consent-button-image" />
        </button>
        <div class="ck-content">
          <div>
            <div>
              <div class="ck-info"><strong>เว็บไซต์นี้ใช้คุกกี้</strong></div>
              <div>
                เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ
                และประสบการณ์ที่ดีในการใช้งานเว็บไซต์
                คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก
                "การตั้งค่าคุกกี้"
                <!-- <a href="#" target="_blank">นโยบายความเป็นส่วนตัว</a> -->
              </div>
            </div>
            <div class="ck-button">
              <div>
                <button
                  class="ck-accept"
                  @click="
                    acceptAll({
                      consent_analytics: true,
                      consent_marketing: true,
                    })
                  "
                >
                  ยอมรับทั้งหมด
                </button>
              </div>
              <div class="ck-setting">
                <div role="button" @click="cookiesSetting">
                  การตั้งค่าคุกกี้
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showModal"
      @close="closeModal"
      @update:consent="acceptAll"
    ></modal>
  </div>
</template>

<script>
import modal from "./modal.vue";
import { _setInitCookie, _getCookie } from "./cookies-consent";
export default {
  components: { modal },
  data() {
    return {
      showModal: false,
      showConsent: false,
      isCookie: false,
    };
  },
  methods: {
    cookiesSetting() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    close() {
      this.showConsent = false;
    },
    acceptAll(value) {
      this.close();
      this.isCookie = false;
      //if analytic accept
      if (value.consent_analytics) {
        this.$gtag.optIn();
        window["ga-disable-G-MM48ZJXJ42"] = false;
      } else {
        this.$gtag.optOut();
        window["ga-disable-G-MM48ZJXJ42"] = true;
      }
      _setInitCookie({
        cookie_name: "consent",
        consent_analytics: value.consent_analytics,
        consent_marketing: value.consent_marketing,
      });
      this.$emit("acceptConsent", {
        consent_analytics: value.consent_analytics,
      });
    },
  },
//   mounted() {
//     if (!_getCookie("consent")) {
//         console.log("no consent");
//       this.isCookie = true;
//       this.showConsent = true;
//       setTimeout(function () {
//         document.querySelectorAll(".ck-body")[0].style.transform =
//           "translateY(0px)";
//       }, 1000);
//     }
//   },
  watch: {
    $route() {
      //if user close consent when route change show again
      if (!_getCookie("consent")) {
        this.showConsent = true;
        setTimeout(function () {
            document.querySelectorAll(".ck-body")[0].style.transform =
            "translateY(0px)";
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
.ck-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1000000;
  .ck-body {
    position: relative;
    max-width: 1024px;
    margin: auto;
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #e9e3e3;
    border-radius: 6px;
    font-weight: 300;
    transition: all 0.5s;
    transform: translateY(300px);
    .ck-content {
      padding: 15px;
      font-size: 16px;
    }
    .ck-info {
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 1rem;
    }
  }
  .ck-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;
    .ck-accept {
      padding: 8px 15px;
      border-radius: 6px;
      border: 1px solid #e9e3e3;
      color: #fff;
      background: #556ee6;
    }
    .ck-setting {
      padding-left: 15px;
      padding-right: 15px;
      color: #556ee6;
    }
  }
}
.close-consent-btn {
  padding: 5px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  right: -5px;
  width: 25px;
  height: 25px;
  margin-top: -12px;
  justify-content: center;
  border: 2px solid #848484;
  img {
    opacity: 0.5;
    width: 100%;
  }
}
</style>