<template>
    <transition name="modal" appear>
        <div class="modal-wrapper" style="transform: translateY(0px); opacity: 1;">
            <!-- <div class="modal-wrapper"> -->
                <div class="modal-container">
                    <header class="modal-headers">
                        <slot name="header"></slot>
                        การตั้งค่าความเป็นส่วนตัว
                        <button
                        type="button"
                        class="btn-modal-close"
                        @click="close"
                        >
                            <img src="./x-symbol.svg"/>
                        </button>
                    </header>

                    <section class="modal-body">
                        <slot name="body"></slot>
                        <div class="setting-content">
                            <div class="items">
                                <div>
                                    <div class="item-header">
                                        <div class="font-bold">คุกกี้พื้นฐานที่จำเป็น</div>
                                        <div class="item-info">
                                            <div>เปิดใช้งานตลอดเวลา</div>
                                        </div>
                                    </div>
                                    <div>
                                        คุกกี้พื้นฐานที่จำเป็น เพื่อช่วยให้การทำงานหลักของเว็บไซต์ใช้งานได้ รวมถึงการเข้าถึงพื้นที่ที่ปลอดภัยต่าง ๆ ของเว็บไซต์ หากไม่มีคุกกี้นี้เว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม และจะใช้งานได้โดยการตั้งค่าเริ่มต้น โดยไม่สามารถปิดการใช้งานได้
                                        <a href="#" >รายละเอียดคุกกี้</a>
                                    </div>
                                </div>
                            </div>
                            <div class="items">
                                <div>
                                    <div class="item-header">
                                        <div class="font-bold">คุกกี้ในส่วนวิเคราะห์</div>
                                        <div class="item-info">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" value="true" id="consent_analytics" v-model="formConsent.consent_analytics">
                                                <label class="custom-control-label" for="consent_analytics"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        คุกกี้ในส่วนวิเคราะห์ จะช่วยให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมและจะช่วยปรับปรุงประสบการณ์การใช้งาน โดยการเก็บรวบรวมข้อมูลและรายงานผลการใช้งานของผู้ใช้งาน
                                    </div>
                                </div>
                            </div>
                            <div class="items">
                                <div>
                                    <div class="item-header">
                                        <div class="font-bold">คุกกี้ในส่วนการตลาด</div>
                                        <div class="item-info">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" value="true" id="consent_marketing" v-model="formConsent.consent_marketing">
                                                <label class="custom-control-label" for="consent_marketing"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        คุกกี้ในส่วนการตลาด ใช้เพื่อติดตามพฤติกรรมผู้เข้าชมเว็บไซต์เพื่อแสดงโฆษณาที่เหมาะสมสำหรับผู้ใช้งานแต่ละรายและเพื่อเพิ่มประสิทธิผล
                                        การโฆษณาสำหรับผู้เผยแพร่และผู้โฆษณาสำหรับบุคคลที่สาม
                                        <a href="#" >รายละเอียดคุกกี้</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer class="modal-footers">
                        <slot name="footer"></slot>
                        <button
                            type="button"
                            class="button btn-secondary btn-secondary-color"
                            @click="close"
                        >
                            ยกเลิก
                        </button>
                        <button
                            type="button"
                            class="button ck-accept mar-l-10"
                            @click="submitSetting"
                        >
                            ยืนยันตัวเลือก
                        </button>
                    </footer>
                </div>
            </div>
        <!-- </div> -->
    </transition>
</template>

<script>
export default {
    data(){
        return {
            formConsent:{
                consent_marketing: false,
                consent_analytics: false
            }
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        submitSetting(){
            this.close()
            this.$emit("update:consent", this.formConsent)
        }
    }
}
</script>

<style lang="scss">
  .modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000001;
    font-size: 1rem;
    font-weight: 300;
    transition: transform 0.2s linear 0s, opacity 0.2s linear 0s;
    padding: 10px;
  }

  .modal-container {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    border-radius: 6px;
    max-height: calc(100vh - 20px);
  }

  .modal-headers,
  .modal-footers {
    padding: 1.25rem;
    display: flex;
  }

  .modal-headers {
    position: relative;
    // border-bottom: 1px solid #eeeeee;
    /* color: #4AAE9B; */
    justify-content: space-between;
    font-weight: 500;
  }

  .modal-footers {
    // border-top: 1px solid #eeeeee;
    // flex-direction: column;
    justify-content: flex-end;
    .button{
        // width: 3rem;
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
    }
  }

  .modal-body {
    position: relative;
    padding: 1.25rem;
  }

  .btn-modal-close {
    position: absolute;
    top: 5px;
    right: 10px;
    border: none;
    font-size: 20px;
    /* padding: 10px; */
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    font-weight: bold;
    /* color: #4AAE9B; */
    background: transparent;
    img{
        width: 100%;
    }
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
  .setting-content{
    border: 1px solid rgb(232, 232, 232);
    .items{
        display: flex;
        border-bottom: 1px solid rgb(232, 232, 232);
        padding: 20px;
        &:last-child{
            border: none;
        }
        .item-header{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            .item-info{
                color: #556ee6;
            }
            .font-bold{
                font-weight: 500;
            }
        }
    }
  }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    &:before{
      pointer-events: none;
      background-color: #fff;
      border: 1px solid #adb5bd;
      position: absolute;
      top: .25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
    }
    &:after{
      position: absolute;
      top: .25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: no-repeat 50%/50% 50%;
    }
  }
}

.custom-switch {
  padding-left: 2.25rem;
  .custom-control-label{
    &:before{
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: .5rem;
    }
    &:after{
      top: calc(.25rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #adb5bd;
      border-radius: .5rem;
      transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
      transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    }
  }
}
.ck-accept{
    padding: 8px 15px;
    border-radius: 6px;
    border: 1px solid #e9e3e3;
    color: #FFF;
    background: #556ee6;
}
.mar-l-10{
    margin-left: 10px;
}
</style>