<script>
import appConfig from "@/app.config";

import { notificationMethods } from "@/state/helpers";
import cookiesConsent from "./components/widgets/cookies-consent/cookies-consent.vue";
import { bootstrap } from "vue-gtag";
import PreLoading from "./components/PreLoading.vue";

export default {
  components: { cookiesConsent, PreLoading },
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl')
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route: function (to, from) {
      this.clearNotification();
      if (to.meta.hideChat) {
        this.$zendesk.hide();
      } else {
        this.$zendesk.show();
      }
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
    enablePlugin() {
      bootstrap().then(() => {
        // all done!
      });
    },
    settingPreference(value) {
      if (value.consent_analytics) {
        this.enablePlugin();
      }
    },
  },
};
</script>

<template>
  <div id="app">
    <cookies-consent @acceptConsent="settingPreference"></cookies-consent>
    <PreLoading />
    <RouterView />
  </div>
</template>
