const defaultState = {
    itemsPerPage: 12,
    itemsStyle: "CARD"
}

export const state = {
    ...defaultState
}

export const getters = {
    itemsSmePerPage: state => state.itemsPerPage,
    itemsStyle: state => state.itemsStyle
}

export const mutations = {
    setItemsPerPage(state, value){
        state.itemsPerPage = value
    },
    setItemsStyle(state, value){
        state.itemsStyle = value
    }
}

export const actions = {
    setItemsPerPage({commit}, value){
        commit("setItemsPerPage", value)
    },
    setItemsStyle({commit}, value){
        commit("setItemsStyle", value)
    }
}