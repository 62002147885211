


const defaultState = {
    productStyleState: 'CARD',
    itemsPerPage: 20
}


export const state = {
    ...defaultState
};

export const getters = {
    // allTodos: state => state.todos,
    productStyleState: state => state.productStyleState,
    itemsPerPage: state => state.itemsPerPage
};

export const mutations = {
    // setTodos(state, newValue) {
    //     state.todos = newValue
    // },
    setProductStyleStateToCard(state){
        state.productStyleState = "CARD"
    },
    setProductStyleStateToList(state){
        state.productStyleState = 'LIST'
    },
    setItemsPerPage(state, value){
        state.itemsPerPage = value
    },
};

export const actions = {
    changeProductStyleStateToList({ commit }) {
        commit('setProductStyleStateToList')
    },
    changeProductStyleStateToCard({ commit }) {
        commit('setProductStyleStateToCard')
    },
    changeItemsPerPage({ commit }, value) {
        commit('setItemsPerPage' ,value)
    },
};
