import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
  ...mapState("auth", {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters("auth", ["loggedIn"]),
};

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};
export const smeDataGetters = {
  ...mapGetters("smedata", [
    "getTotalSme",
    "getPreSearchFilter",
    "getPreapproveSortTable",
    "getPreapproveRowPerPage",
    "getApproveSearchFilter",
    "getApproveSortTable",
    "getApproveRowPerPage",
    "getTotalProduct",
    "getPreRenewSearchFilter",
    "getPreRenewSortTable",
    "getPreRenewRowPerPage",
    "getRenewSearchFilter",
    "getRenewSortTable",
    "getRenewRowPerPage",
    "getTotalReportProduct"
  ]),
};

export const productComputed = {
  ...mapGetters("product", ["productStyleState", "itemsPerPage"]),
};

export const authMethods = mapActions("auth", [
  "logIn",
  "logOut",
  "register",
  "resetPassword",
]);

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const authFackMethods = mapActions("authfack", [
  "login",
  "registeruser",
  "logout",
]);

export const notificationMethods = mapActions("notification", [
  "success",
  "error",
  "clear",
]);

export const smeDataMethods = mapActions("smedata", [
  "fetchTotalSme",
  "preapproveFilterSearch",
  "preApproveSortTable",
  "preApproveRowPerPage",
  "approveFilterSearch",
  "approveSortTable",
  "approveRowPerPage",
  "fetchTotalProduct",
  "preRenewFilterSearch",
  "preRenewSortTable",
  "preRenewRowPerPage",
  "renewFilterSearch",
  "renewSortTable",
  "renewRowPerPage",
  "fetchTotalReportProduct"
]);

export const todoComputed = {
  ...mapState("todo", {
    todos: (state) => state.todos,
  }),
};
export const todoMethods = mapActions("todo", ["fetchTodos"]);

export const productMethods = mapActions("product", [
  "changeProductStyleStateToCard",
  "changeItemsPerPage",
  "changeProductStyleStateToList",
]);

export const smesGetters = {
  ...mapGetters("smes",[
    "itemsSmePerPage",
    "itemsStyle"
  ])
}

export const smesMethods = mapActions("smes", [
  "setItemsPerPage",
  "setItemsStyle"
])
