var _config = {
  cookie_expiration: 365, // default: 365 (in days)
  cookie_domain: window.location.hostname, // default: current domain
  cookie_path: "/",
  cookie_same_site: "Lax",
  autoclear_cookies: true,
  cookie_name: "ck-consent",
  consent_analytics: false,
  consent_marketing: false,
};
var _setInitCookie = function(user_config) {
  _setCookieData(user_config);
};
var _uuidv4 = function() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function(c) {
    return (
      c ^
      ((window.crypto || window.msCrypto).getRandomValues(
        new Uint8Array(1)
      )[0] &
        (15 >> (c / 4)))
    ).toString(16);
  });
};

var _setCookieData = function(user_config) {
  if (typeof user_config["cookie_expiration"] === "number")
    _config.cookie_expiration = user_config["cookie_expiration"];

  if (typeof user_config["cookie_domain"] === "string")
    _config.cookie_domain = user_config["cookie_domain"];

  if (typeof user_config["cookie_same_site"] === "string")
    _config.cookie_same_site = user_config["cookie_same_site"];

  if (typeof user_config["cookie_path"] === "string")
    _config.cookie_path = user_config["cookie_path"];

  if (typeof user_config["cookie_name"] === "string")
    _config.cookie_name = user_config["cookie_name"];

  if (typeof user_config["consent_analytics"] === "boolean")
    _config.consent_analytics = user_config["consent_analytics"];

  if (typeof user_config["consent_marketing"] === "boolean")
    _config.consent_marketing = user_config["consent_marketing"];

  var cookie_data = {
    consent_analytics: _config.consent_analytics,
    consent_marketing: _config.consent_marketing,
    uuid: _uuidv4(),
    last_updated: Date.now(),
  };
  var isExist = _getCookie(_config.cookie_name);
  if (!isExist) _setCookie(_config.cookie_name, JSON.stringify(cookie_data));
};

var _setCookie = function(name, value) {
  var date = new Date();
  date.setTime(
    date.getTime() + 1000 * (_config.cookie_expiration * 24 * 60 * 60)
  );
  var expires = "; expires=" + date.toUTCString();

  var cookieStr =
    name +
    "=" +
    (value || "") +
    expires +
    "; Path=" +
    _config.cookie_path +
    ";";
  cookieStr += " SameSite=" + _config.cookie_same_site + ";";

  // assures cookie works with localhost (=> don't specify domain if on localhost)
  if (window.location.hostname.indexOf(".") > -1) {
    cookieStr += " Domain=" + _config.cookie_domain + ";";
  }

  if (window.location.protocol === "https:") {
    cookieStr += " Secure;";
  }
  document.cookie = cookieStr;
};
var _getCookie = function(name) {
  var found = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  if (!found) _manageExistingCookie();
  return found;
};
var _manageExistingCookie = function() {
  //get google cookie
  let existCookie = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("_ga"));
  if (existCookie?.length > 0) {
    var expires = "Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

    for (var i = 0; i < existCookie.length; i++) {
      document.cookie =
        existCookie[i] +
        "=; path=" +
        _config.cookie_path +
        "; domain=" +
        _config.cookie_domain +
        "; " +
        expires;
    }
  }
};

export { _setCookie, _setInitCookie, _getCookie };
