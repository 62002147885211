export const state = {
    endpoints: {
        api: " ",
        login: " "
    },
    user: {
        isAuthenticated: false,
        name: "",
        idToken: "",
        accessToken: ""
    }
}

export const mutations = {
    setAPI(state, payload) {
        state.endpoints.api = payload.api;
    },
    setAPIAndLogin(state, payload) {
        state.endpoints.api = payload.api;
        state.endpoints.login = payload.login;
    },
    logout(state) {
        state.user.isAuthenticated = false;
        state.user.name = "";
        state.user.idToken = "";
        state.user.accessToken = "";
    },
    login(state, payload) {
        state.user.isAuthenticated = true;
        state.user.idToken = payload.idToken;
        state.user.accessToken = payload.accessToken;
    },
    setName(state, payload) {
        state.user.name = payload.name;
    },
}

export const actions = {}