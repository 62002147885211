import { publicData } from "@/router/defineVar";
import axios from "axios";
const preapproveDefaultState = {
  preSearchFilter: {
    id: "",
    type: "",
    name: "",
    sector: "",
    size: "",
    year: "",
    diffdate: "",
    status: "",
  },
  preapproveSortTable: {
    sortDesc: true,
    sortBy: "diffdate",
    columnName: "CREATE_DATEDIFF",
  },
  preapproveRowPerPage: 10,
};
const approveDefaultState = {
  approveSearchFilter: {
    id: "",
    type: "",
    name: "",
    sector: "",
    size: "",
    result: "",
    diffdate: "",
    review_by: "",
  },
  approveSortTable: {
    sortDesc: true,
    sortBy: "diffdate",
    columnName: "CREATE_DATEDIFF",
  },
  approveRowPerPage: 10,
};
const preRenewDefaultState = {
  preRenewSearchFilter: {
    id: "",
    type: "",
    name: "",
    sector: "",
    size: "",
    year: "",
    diffdate: "",
    status: "",
  },
  preRenewSortTable: {
    sortDesc: true,
    sortBy: "diffdate",
    columnName: "RENEW_DATEDIFF",
  },
  preRenewRowPerPage: 10,
};
const renewDefaultState = {
  renewSearchFilter: {
    id: "",
    type: "",
    name: "",
    sector: "",
    size: "",
    result: "",
    diffdate: "",
    review_by: "",
  },
  renewSortTable: {
    sortDesc: true,
    sortBy: "diffdate",
    columnName: "RENEW_DATEDIFF",
  },
  renewRowPerPage: 10,
};
export const state = {
  ...preapproveDefaultState,
  ...approveDefaultState,
  ...preRenewDefaultState,
  ...renewDefaultState,
  totalStatus: {
    SME_TOTAL: null,
    NEW: null,
    WAITING_UPDATE: null,
    REVIEWED: null,
    APPROVED: null,
    REJECTED: null,
    INCOMPLETE: null,
  },
  totalProduct: {
    PRODUCT_TOTAL: null,
    NEW: null,
    PROCESS: null,
    REVIEWED: null,
    APPROVED: null,
    REJECTED: null,
  },
  totalReportProduct: {
    TOTAL: null,
    NEW: null,
    DRAFT: null,
    REVIEWED: null,
    APPROVED: null,
    REJECTED: null,
    REMOVED: null,
  },
  // approveUserRole: "preapprove"
};
export const getters = {
  getTotalSme: (state) => state.totalStatus,
  getPreSearchFilter: (state) => state.preSearchFilter,
  getPreapproveSortTable: (state) => state.preapproveSortTable,
  getPreapproveRowPerPage: (state) => state.preapproveRowPerPage,
  getApproveSearchFilter: (state) => state.approveSearchFilter,
  getApproveSortTable: (state) => state.approveSortTable,
  getApproveRowPerPage: (state) => state.approveRowPerPage,
  getTotalProduct: (state) => state.totalProduct,
  getPreRenewSearchFilter: (state) => state.preRenewSearchFilter,
  getPreRenewSortTable: (state) => state.preRenewSortTable,
  getPreRenewRowPerPage: (state) => state.preRenewRowPerPage,
  getRenewSearchFilter: (state) => state.renewSearchFilter,
  getRenewSortTable: (state) => state.renewSortTable,
  getRenewRowPerPage: (state) => state.renewRowPerPage,
  getTotalReportProduct: (state) => state.totalReportProduct,
  // getRoleStatus: (state) => state.approveUserRole
};

export const mutations = {
  setTotalSme(state, data) {
    state.totalStatus = data;
  },
  setPreapproveFilterSearch(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.preSearchFilter[key] = value;
    }
  },
  setPreApproveSortTable(state, data) {
    state.preapproveSortTable = data;
  },
  setPreApproveRowPerPage(state, data) {
    state.preapproveRowPerPage = data;
  },
  setApproveFilterSearch(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.approveSearchFilter[key] = value;
    }
  },
  setApproveSortTable(state, data) {
    state.approveSortTable = data;
  },
  setApproveRowPerPage(state, data) {
    state.approveRowPerPage = data;
  },
  setTotalProduct(state, data) {
    state.totalProduct = data;
  },
  setPreRenewFilterSearch(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.preRenewSearchFilter[key] = value;
    }
  },
  setPreRenewSortTable(state, data) {
    state.preRenewSortTable = data;
  },
  setPreRenewRowPerPage(state, data) {
    state.preRenewRowPerPage = data;
  },
  setRenewFilterSearch(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.renewSearchFilter[key] = value;
    }
  },
  setRenewSortTable(state, data) {
    state.renewSortTable = data;
  },
  setRenewRowPerPage(state, data) {
    state.renewRowPerPage = data;
  },
  setTotalReportProduct(state, data){
    state.totalReportProduct = data
  }
  /* setRoleFromPageClick(state,data) {
    state.approveUserRole = data
  } */
};

export const actions = {
  fetchTotalSme({ commit }) {
    return new Promise((resolve, reject) => {
      const config = {
        url: `${publicData.apiURL}sme/count`,
        method: "GET",
      };
      axios(config)
        .then((response) => {
          let tempData = {
            SME_TOTAL: 0,
            NEW: 0,
            WAITING_UPDATE: 0,
            REVIEWED: 0,
            APPROVED: 0,
            REJECTED: 0,
            INCOMPLETE: 0,
          };
          tempData["SME_TOTAL"] = response.data[0]["TOTAL"];
          for (let i in response.data[0].SME) {
            tempData[response.data[0].SME[i]["STATUS"]] =
              response.data[0].SME[i]["COUNT"];
          }
          commit("setTotalSme", tempData);

          resolve("success");
        })
        .catch(() => {
          reject("error");
        });
    });
  },
  preapproveFilterSearch({ commit }, payload) {
    commit("setPreapproveFilterSearch", payload);
  },
  preApproveSortTable({ commit }, payload) {
    commit("setPreApproveSortTable", payload);
  },
  preApproveRowPerPage({ commit }, payload) {
    commit("setPreApproveRowPerPage", payload);
  },
  approveFilterSearch({ commit }, payload) {
    commit("setApproveFilterSearch", payload);
  },
  approveSortTable({ commit }, payload) {
    commit("setApproveSortTable", payload);
  },
  approveRowPerPage({ commit }, payload) {
    commit("setApproveRowPerPage", payload);
  },
  preRenewFilterSearch({ commit }, payload) {
    commit("setPreRenewFilterSearch", payload);
  },
  preRenewSortTable({ commit }, payload) {
    commit("setPreRenewSortTable", payload);
  },
  preRenewRowPerPage({ commit }, payload) {
    commit("setPreRenewRowPerPage", payload);
  },
  renewFilterSearch({ commit }, payload) {
    commit("setRenewFilterSearch", payload);
  },
  renewSortTable({ commit }, payload) {
    commit("setRenewSortTable", payload);
  },
  renewRowPerPage({ commit }, payload) {
    commit("setRenewRowPerPage", payload);
  },
  fetchTotalProduct({ commit }) {
    return new Promise((resolve, reject) => {
      const config = {
        url: `${publicData.apiURL}sme/countproduct`,
        method: "GET",
      };
      axios(config)
        .then((response) => {
          let tempData = {
            PRODUCT_TOTAL: 0,
            NEW: 0,
            PROCESS: 0,
            REVIEWED: 0,
            APPROVED: 0,
            REJECTED: 0,
          };
          tempData["PRODUCT_TOTAL"] = response.data[0]["TOTAL"];
          for (let i in response.data[0].PRODUCT) {
            tempData[response.data[0].PRODUCT[i]["STATUS"]] =
              response.data[0].PRODUCT[i]["COUNT"];
          }
          commit("setTotalProduct", tempData);

          resolve("success");
        })
        .catch(() => {
          reject("error");
        });
    });
  },
  fetchTotalReportProduct({commit}) {
    return new Promise((resolve, reject) => {
      const config = {
        url: `${publicData.apiURL}dashboard/productCountStatus`,
        method: "GET",
      };
      axios(config)
        .then((response) => {
          let tempData = {
            TOTAL: 0,
            NEW: 0,
            DRAFT: 0,
            REVIEWED: 0,
            APPROVED: 0,
            REJECTED: 0,
            REMOVED: 0,
          };
          for (let i of Object.keys(response.data.total)) {
            tempData[i] = response.data.total[i];
          }
          commit("setTotalReportProduct", tempData);

          resolve("success");
        })
        .catch(() => {
          reject("error");
        });
    });
  },
  /* changeRole({ commit }, userRole) {
    commit("setRoleFromPageClick",userRole)
  } */
};
